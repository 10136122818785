const ROWRENDERERCONST = {
    INSPECTION_POINTS: "INSPECTION_POINTS",
    ASSIGNMENT: "ASSIGNMENT",
    ONTWERPVERSLAG: "ONTWERPVERSLAG",
    REQUEST: "REQUEST",
    LINKED_REQUEST: "LINKED_REQUEST",
    CONTACT_PERSONS: "CONTACT_PERSONS",
    REPORTS: "REPORTS",
    FINAL_REPORTS: "FINAL_REPORTS",
    DRAFT_REPORTS: "DRAFT_REPORTS",
    INTERNAL_MESSAGES: "INTERNAL_MESSAGES",
    INFRINGEMENTS: "INFRINGEMENTS",
    NOTES: "NOTES",
    INFRINGEMENT_HISTORY: "INFRINGEMENT_HISTORY",
    ASSIGNMENT_HISTORY: "ASSIGNMENT_HISTORY",
    INSPECTION_POINT_HISTORY: "INSPECTION_POINT_HISTORY",
    EXEMPTIONS: "EXEMPTIONS",
    ADVANCED_ASSIGNMENTS: "ADVANCED_ASSIGNMENTS",
    ADVANCED_REQUESTS: "ADVANCED_REQUESTS",
    REGIONAL_DISTRIBUTION: "REGIONAL_DISTRIBUTION",
    REQUEST_ATTACHMENTS: "REQUEST_ATTACHMENTS",
    REQUEST_HISTORY: "REQUEST_HISTORY",
    ATTACHMENTS: "ATTACHMENTS",
    CALENDAR: "CALENDAR",
    COMMUNICATIONS: "COMMUNICATIONS",
    ADMIN_DISCLOSURES: "ADMIN_DISCLOSURES",
    REPORT_TYPES_VERSIONS: "REPORT_TYPES_VERSIONS",
};

export default ROWRENDERERCONST;
