// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { loadInspection } from "./inspection";
import { NOTIFICATIONS } from "@constants";

const { constants: _constants, reducer, actions } = createApiModule(
    "reopenAssignment",
    ({ inspectionId }) => `/inspections/${inspectionId}/conclusions`,
    {
        method: "DELETE",
        errorHandler: notifyApiError("Opdracht heropenen mislukt"),
    },
);

export const reopenAssignment = (inspectionId: string, assigneeId?: string) => (
    dispatch: *,
) => {
    const data = assigneeId ? { assigneeId } : undefined;
    return dispatch(load({ path: { inspectionId }, data }, true)).then(
        action => {
            if (!action || action.type !== constants.SUCCESS) return null;

            dispatch(
                notify({
                    severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                    message: "Opdracht is succesvol heropend",
                }),
            );

            return dispatch(loadInspection(inspectionId));
        },
    );
};
export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
