export const INFRINGEMENTS = {
    LABELS: {
        ACTIVE: "Actief",
        RESOLVED: "Afgesloten",
        SUPERSEDED: "Vervangen",
    },
    STATUSES: {
        ACTIVE: "ACTIVE",
        RESOLVED: "RESOLVED",
        SUPERSEDED: "SUPERSEDED",
    },
};
