// @flow

export const isComplaint = (reason: *) => {
    if (!reason || (typeof reason !== "string" && !reason.shortDescription))
        return false;
    const reasonValue =
        typeof reason === "string"
            ? reason
            : reason.shortDescription.toLowerCase();
    return (
        reasonValue.includes("klacht") ||
        reasonValue.includes("ernstige gebeurtenis") ||
        reasonValue.includes("klacht/gevaarsituaties")
    );
};
