// @flow
import { ASSIGNMENTS, REQUESTS } from "@constants";
import type { Permission } from "@types";
//Roles
const ROLE_SECRETARIAT = "ROLE_SECRETARIAT";
const ROLE_INSPECTOR = "ROLE_INSPECTOR";
const ROLE_ADMIN = "ROLE_ADMIN";
const ROLE_MINIADMIN = "ROLE_MINIADMIN";
const ROLE_EXTERNAL = "ROLE_EXTERNAL";

//Define permission per page
type PermissionDefinition = {
    [string]: Permission | Permission[],
};
const definition: PermissionDefinition = {
    //Requests
    "requests.selectTeamAssignment.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(REQUESTS.STATUSES),
    },
    "requests.selectOwnAssignment.write": {
        roles: [ROLE_INSPECTOR],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    /**
     * request details
     */
    "requestDetails.validator.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(REQUESTS.STATUSES),
    },
    "requestDetails.assignee.read": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT, ROLE_INSPECTOR],
        statuses: Object.values(REQUESTS.STATUSES),
    },
    "requestDetails.leadInspector.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(REQUESTS.STATUSES),
    },
    "requestDetails.edit.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(REQUESTS.STATUSES),
    },
    "requestDetails.editExternalReference.write": {
        roles: [ROLE_ADMIN],
        statuses: Object.values(REQUESTS.STATUSES),
    },
    // "requestDetails.edit.fields.read": {
    //     roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT, ROLE_INSPECTOR],
    //     statuses: Object.values(REQUESTS.STATUSES),
    // },
    "requestDetails.attachments.add.write": [
        {
            roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
            statuses: Object.values(REQUESTS.STATUSES),
        },
        {
            roles: [ROLE_EXTERNAL],
            statuses: [REQUESTS.STATUSES.CREATED],
        },
    ],
    "requestDetails.attachments.delete.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT, ROLE_MINIADMIN],
        statuses: Object.values(REQUESTS.STATUSES),
    },
    "requestDetails.downloadReports.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT, ROLE_EXTERNAL],
        statuses: Object.values(REQUESTS.STATUSES),
    },
    "requestDetails.assignmentLink.read": {
        roles: [
            ROLE_ADMIN,
            ROLE_MINIADMIN,
            ROLE_SECRETARIAT,
            ROLE_INSPECTOR,
            ROLE_EXTERNAL,
        ],
        statuses: Object.values(REQUESTS.STATUSES),
    },
    "requestDetails.cancelrequest.write": [
        {
            roles: [ROLE_ADMIN, ROLE_SECRETARIAT],
            statuses: ["CREATED", "VALIDATION_REQUESTED", "VALIDATED"],
        },
        {
            roles: [ROLE_MINIADMIN],
            statuses: ["CREATED", "VALIDATED"],
        },
    ],
    "requestDetails.history.read": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT, ROLE_MINIADMIN],
        statuses: [],
    },
    "requestDetails.mainVisit.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: ["CREATED", "VALIDATION_REQUESTED", "VALIDATED"],
    },
    /**
     *   Assignment
     */
    "assignment.lastObservationDate.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT, ROLE_MINIADMIN],
        statuses: [],
    },
    "assignment.inspectors.write": {
        roles: [ROLE_SECRETARIAT, ROLE_ADMIN, ROLE_MINIADMIN],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "assignment.reviewer.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN],
        statuses: [],
    },
    "assignment.leadInspector.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: [],
    },
    "assignment.assignToSelf.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT],
        statuses: [
            ASSIGNMENTS.STATUSSES.DRAFT_READY_TO_PUBLISH,
            ASSIGNMENTS.STATUSSES.DRAFT_REPORT_PUBLISHED,
            ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
            ASSIGNMENTS.STATUSSES.FINAL_REPORT_PUBLISHED,
            ASSIGNMENTS.STATUSSES.REOPENED,
        ],
    },
    "assignment.externalReference.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: [
            ASSIGNMENTS.STATUSSES.CREATED,
            ASSIGNMENTS.STATUSSES.PLANNED,
            ASSIGNMENTS.STATUSSES.PREPARATION_STARTED,
            ASSIGNMENTS.STATUSSES.PREPARED,
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
        ],
    },
    "assignment.editDetails.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: [
            ASSIGNMENTS.STATUSSES.CREATED,
            ASSIGNMENTS.STATUSSES.PLANNED,
            ASSIGNMENTS.STATUSSES.PREPARATION_STARTED,
            ASSIGNMENTS.STATUSSES.PREPARED,
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
        ],
    },
    "assignment.forms.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: [],
    },
    "assignment.networkLabel.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: [],
    },
    "assignment.footer.read": {
        roles: [ROLE_SECRETARIAT, ROLE_ADMIN, ROLE_MINIADMIN],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "assignment.actionMenu.read": {
        roles: [ROLE_SECRETARIAT, ROLE_ADMIN, ROLE_MINIADMIN],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "assignment.disclosureLevel.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "assignment.allowReactions.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "assignment.close.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT],
        statuses: [
            ASSIGNMENTS.STATUSSES.FINAL_REPORT_PUBLISHED,
            ASSIGNMENTS.STATUSSES.REOPENED,
        ],
    },
    "assignment.reopen.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT],
        statuses: [ASSIGNMENTS.STATUSSES.CLOSED],
    },
    "assignment.cancel.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT, ROLE_MINIADMIN],
        statuses: [],
    },
    "assignment.postpone.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT, ROLE_MINIADMIN],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "assignment.endPreparation.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN],
        statuses: [
            ASSIGNMENTS.STATUSSES.PREPARATION_STARTED,
            ASSIGNMENTS.STATUSSES.PLANNED,
        ],
    },
    "assignment.startQualityControl.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN],
        statuses: [],
    },
    "assignment.backToInspector.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "assignment.completeFeedback.write": {
        roles: [ROLE_ADMIN],
        statuses: [],
    },
    "assignment.mainVisit.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: [
            ASSIGNMENTS.STATUSSES.CREATED,
            ASSIGNMENTS.STATUSSES.PLANNED,
            ASSIGNMENTS.STATUSSES.PREPARED,
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
        ],
    },
    "assignment.interlocutors.write": [
        {
            roles: [ROLE_ADMIN, ROLE_SECRETARIAT],
            statuses: [
                ASSIGNMENTS.STATUSSES.DRAFT_READY_TO_PUBLISH,
                ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
                ASSIGNMENTS.STATUSSES.REOPENED,
            ],
        },
        {
            roles: [ROLE_ADMIN, ROLE_MINIADMIN],
            statuses: [
                ASSIGNMENTS.STATUSSES.REACTION_RECIEVED,
                ASSIGNMENTS.STATUSSES.FINAL_REVIEWED,
            ],
        },
    ],
    //Verslagen
    "reports.removeReport.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "reports.sendReport.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "reports.publishReport.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "reports.rejectReport.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "reports.addReport.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "reports.markAsSent.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "reports.overwrite.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT, ROLE_MINIADMIN],
        statuses: [
            ASSIGNMENTS.STATUSSES.REOPENED,
            ASSIGNMENTS.STATUSSES.DRAFT_READY_TO_PUBLISH,
            ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
        ],
    },
    "reports.createDraftReport.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: [
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
            ASSIGNMENTS.STATUSSES.PREPARED,
            ASSIGNMENTS.STATUSSES.DRAFT_READY_TO_PUBLISH,
        ],
    },
    "reports.createFinalReport.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: [
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
            ASSIGNMENTS.STATUSSES.PREPARED,
            ASSIGNMENTS.STATUSSES.REACTION_PROCESSED,
            ASSIGNMENTS.STATUSSES.FINAL_REVIEWED,
            ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
            ASSIGNMENTS.STATUSSES.REOPENED,
        ],
    },
    "reports.markReadyToSend.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN],
        statuses: [],
    },
    // Reacties
    "reaction.stopReaction.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "reaction.crud.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "reaction.process.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    //Interne-communicatie & Nota's en bijlagen
    "assignment.notes.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "assignment.deleteAttachments.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "communication.sendEmail.write": {
        roles: [ROLE_ADMIN, ROLE_SECRETARIAT],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    /*
     *   Inspectionpoint Dossier
     */
    "inspectionPointDossier.editInspectionPoint.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT, ROLE_INSPECTOR],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    "inspectionPointDossier.infringementDetails.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT, ROLE_INSPECTOR],
        statuses: Object.values(ASSIGNMENTS.STATUSSES),
    },
    /**
     *  Opdracht aanmaken
     */
    "createInspection.assignLeadInspector.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: [], // there are no statuses here
    },
    /**
     *  aanvraag aanmaken
     */
    "createRequest.assignLeadInspector.write": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: [], // there are no statuses here
    },
    /**
     *   Navigation
     */
    "navigation.assignments.own.read": {
        roles: [
            ROLE_ADMIN,
            ROLE_MINIADMIN,
            ROLE_SECRETARIAT,
            ROLE_INSPECTOR,
            ROLE_EXTERNAL,
        ],
        statuses: [],
    },
    "navigation.assignments.all.read": {
        roles: [
            ROLE_ADMIN,
            ROLE_MINIADMIN,
            ROLE_SECRETARIAT,
            ROLE_INSPECTOR,
            ROLE_EXTERNAL,
        ],
        statuses: [],
    },
    "navigation.requests.all.read": {
        roles: [
            ROLE_ADMIN,
            ROLE_MINIADMIN,
            ROLE_SECRETARIAT,
            ROLE_INSPECTOR,
            ROLE_EXTERNAL,
        ],
        statuses: [],
    },
    "navigation.searchInspectionPoint.read": {
        roles: [
            ROLE_ADMIN,
            ROLE_MINIADMIN,
            ROLE_SECRETARIAT,
            ROLE_INSPECTOR,
            ROLE_EXTERNAL,
        ],
        statuses: [],
    },
    "navigation.advancedSearch.read": {
        roles: [
            ROLE_ADMIN,
            ROLE_MINIADMIN,
            ROLE_SECRETARIAT,
            ROLE_INSPECTOR,
            ROLE_EXTERNAL,
        ],
        statuses: [],
    },
    "navigation.admin.read": {
        roles: [ROLE_ADMIN, ROLE_MINIADMIN, ROLE_SECRETARIAT],
        statuses: [],
    },
};

export default definition;
