// @flow

import createApiModule from "../create-api-module";
import { notifyApiError, update as notify } from "./notifications";
import { NOTIFICATIONS } from "@constants";
import { load as loadFormsSummary } from "./forms-summary";

const { constants: _constants, reducer, actions } = createApiModule(
    "formsScheduleActivation",
    () => `/forms/schedule/activation`,
    {
        method: "POST",
        errorHandler: notifyApiError("Formulier activeren mislukt"),
    },
);
export const formsScheduleActivation = (data: *) => (dispatch: *) => {
    dispatch(clear());
    return dispatch(load({ data }, true)).then(action => {
        if (!action || action.type !== constants.SUCCESS) return false;

        dispatch(
            notify({
                severity: NOTIFICATIONS.SEVERITY.SUCCESS,
                message: "Formulier succesvol geactiveerd",
            }),
        );
        dispatch(loadFormsSummary(undefined, true));
    });
};

export default reducer;

export const clear = actions.clear;
export const load = actions.load;
export const refetch = actions.refetch;

export const constants = _constants;
