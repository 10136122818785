// @flow
import moment from "moment-timezone";

import { ASSIGNMENTS } from "@constants";
import { isArrayWithContent } from "../arrays";
import { isComplaint } from "../requests";
import type { Person } from "@types";

/*
 *   Returns the reviewer if filled in and the status is appropriate
 *   returns undefined if not.
 */
export const getReviewer = (assignmetnObject: *) => {
    const reviewerStatuses = [
        ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
        ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED,
    ];
    const shouldHaveReviewer = reviewerStatuses.some(
        status => status === assignmetnObject.status,
    );
    return shouldHaveReviewer ? assignmetnObject.assignee : undefined;
};

export const userIsMemberOfAssignment = (assignment: *, userId: string) => {
    let memberIds = [];
    if (assignment.leadInspector) memberIds.push(assignment.leadInspector.id);
    if (assignment.assignee) memberIds.push(assignment.assignee.id);
    if (isArrayWithContent(assignment.inspectors))
        memberIds = memberIds.concat(
            assignment.inspectors.map(person => person.id),
        );

    if (!isArrayWithContent(memberIds)) return false;
    return memberIds.filter(id => !!id).some(id => id === userId);
};

export const userIsMemberOfAssignmentButNotAssignee = (
    assignment: *,
    userId: string,
) => {
    let relevantMemberIds = [];

    if (assignment.leadInspector)
        relevantMemberIds.push(assignment.leadInspector.id);

    if (isArrayWithContent(assignment.inspectors))
        relevantMemberIds = relevantMemberIds.concat(
            assignment.inspectors.map((el: Person) => el.id),
        );

    return relevantMemberIds.some((id: string) => id === userId);
};

export const getAllAvailableInspectors = (assignment: *) => {
    const allInspectors =
        assignment.assignee?.id === assignment.leadInspector?.id
            ? [assignment.leadInspector]
            : [assignment.assignee, assignment.leadInspector];
    if (isArrayWithContent(assignment.inspectors)) {
        allInspectors.push(...assignment.inspectors);
    }

    return (allInspectors: any).filter(inspector => !!inspector);
};

/*
 *   Returns the date of the latest visit
 *   even if lastVisitDoneOn is not filled
 *   is this overkill? not sure
 */
export const getLatestInspectionDate = (assignment: *) => {
    if (assignment.lastVisitDoneOn) return assignment.lastVisitDoneOn;
    if (!isArrayWithContent(assignment.visits)) return null;
    const latestDateReducer = (date, curr) => {
        if (!date && curr) return moment(curr);
        return curr && moment(curr).isAfter(date) ? moment(curr) : date;
    };
    const latestVisitDate = assignment.visits.reduce((date, visit) => {
        let datesForVisit = visit.planVisitDate ? [visit.planVisitDate] : [];
        if (isArrayWithContent(visit.visitPeriods))
            datesForVisit = datesForVisit.concat(
                visit.visitPeriods.map(period => period.endVisitDate),
            );
        const latestInVisit = datesForVisit.reduce(latestDateReducer, null);
        return latestDateReducer(date, latestInVisit);
    }, null);
    return latestVisitDate;
};

export const recordHasComplaint = (record: *) => {
    const combinedReasons = [
        ...(isArrayWithContent(record.reasons) ? record.reasons : []),
        ...(isArrayWithContent(record.requests)
            ? record.requests.map(request => request.reason)
            : []),
    ];

    return (
        isArrayWithContent(combinedReasons) && combinedReasons.some(isComplaint)
    );
};
